<template>
    <div style="width: inherit">
        <hb-expansion-panel key="apw_setting" v-if="apwSettingState">
            <template v-slot:title> Advanced Reservations and Rentals</template>
            <template v-if="isPropertyLevel && settingPermission" v-slot:actions>
                <hb-switch v-model="enabledProperty" v-if="previousSpaceGroupProfile?.id" @click.stop @change="updatePropertyFeature" right>
                    <!-- <template v-slot:label>
                        {{enabledProperty ? 'Disable for this Property' : 'Enable for this Property'}}
                    </template> -->
                </hb-switch>
            </template>
            <template v-slot:content>
                <hb-form label="Cut-Off Time By Channel"
                    description="The cut-off time is a daily time limit that determines when the booking window for a given day closes and shifts to the next available day.">
                    <v-row class="cut-off-row-container ma-0 pa-0">
                        <v-col class="ma-0 pa-0">
                            In-Store Cut Off Time:
                        </v-col>
                        <v-col class="ma-0 pa-0" v-if="permission || inStoreTime">
                            <HbSelect condensed box width="110px" :items="timeOptions" data-vv-scope="default" id="type"
                                name="type" v-model="inStoreTime" :placeholder="'Select Time'" :readonly="!permission"/>
                        </v-col>
                    </v-row>
                    <v-row class="cut-off-row-container ma-0 pa-0" v-if="permission || onlineStoreTime">
                        <v-col class="ma-0 pa-0">
                            Online Cut Off Time:
                        </v-col>
                        <v-col class="ma-0 pa-0">
                            <HbSelect condensed box width="110px" :items="timeOptions" data-vv-scope="default" id="type"
                                name="type" v-model="onlineStoreTime" :placeholder="'Select Time'" :readonly="!permission"/>
                        </v-col>
                    </v-row>
                </hb-form>
                <hb-form label="Space Grouping Profile" required v-if="isPropertyLevel">
                    <hb-select v-model="form.spaceGroupProfile" name="space_group" data-vv-as="space grouping profile"
                        placeholder="Select Space Grouping Profile" :items="spaceGroups" item-text="name" :item-value="(value) => {
                            return { id: value.id, name: value.name };
                        }" :clearable="false" :readonly="!settingPermission" @focus="checkForPermissions">
                    </hb-select>
                </hb-form>
            </template>
            <template v-slot:footer>
                <hb-bottom-action-bar :top-border="false" @close="resetOriginal">
                    <template v-slot:right-actions>
                        <hb-btn :disabled="apwSaveBtnDisable" @click="settingConfirmation">Save</hb-btn>
                    </template>
                </hb-bottom-action-bar>
            </template>
        </hb-expansion-panel>
        <hb-modal :title="confirmationContent.title" show-help-link v-model="confirmDialog" confirmation>
            <template v-slot:content>
                <div class="py-4 px-6" v-html="confirmationContent.message"></div>
            </template>
            <template v-slot:right-actions>
                <hb-btn color="primary" @click="saveSetting" 
                >Continue</hb-btn
                >
            </template>
        </hb-modal>
    </div>
</template>

<script>
import api from '../../../assets/api';
import { notificationMixin } from '../../../mixins/notificationMixin';
import { mapGetters } from "vuex";
export default {
    data() {
        return {
            spaceGroups: [],
            form: {
                spaceGroupProfile: {},
                instoreCutoffTime: '',
                onlineCutoffTime: '',
            },
            previousSpaceGroupProfile: {},
            timeOptions: ["12:00 AM", "1:00 AM", "2:00 AM", "3:00 AM", "4:00 AM", "5:00 AM", "6:00 AM", "7:00 AM", "8:00 AM", "9:00 AM", "10:00 AM", "11:00 AM", "12:00 PM", "1:00 PM", "2:00 PM", "3:00 PM", "4:00 PM", "5:00 PM", "6:00 PM", "7:00 PM", "8:00 PM", "9:00 PM", "10:00 PM", "11:00 PM",],
            inStoreTime: '',
            onlineStoreTime: '',
            message: ['Cut-off Time Saved Successfully', 'Advanced Reservations and Rentals Space Grouping Profile has been updated successfully.'],
            apwSettingState: false,
            confirmDialog: false,
            enabledProperty: false,
            showPermissionsWarning: true,
        }
    },
    mixins: [notificationMixin],
    props: ['property_id', 'isPropertyLevel'],
    computed: {
        ...mapGetters({
        hasPermission: 'authenticationStore/rolePermission',
        }),
    },
    async created() {
        await this.fetchSettings()
        if (this.isPropertyLevel) {
            await this.fetchPropertySpaceGroup();
            await this.fetchSpaceGroup();
        }
        await this.fetchCutOffTime()
    },
    computed: {
        apwSaveBtnDisable(){
            if(this.inStoreTime != this.form?.instoreCutoffTime || this.onlineStoreTime != this.form?.onlineCutoffTime || this.previousSpaceGroupProfile?.unit_group_profile_id != this.form?.spaceGroupProfile?.id) {
                return false
            }else {
                return true
            }
        },
        confirmationContent() {
            return {
                title: "Confirm default space group profile change",
                message:
                "You are about to change the default space group profile" +
                `${
                    this.previousSpaceGroupProfile.id ? " from <strong>" + this.previousSpaceGroupProfile.grouping_profile_name + "</strong>" : ""
                } to <strong>${
                    this.form?.spaceGroupProfile?.name
                }</strong>. Do you want to proceed?`,
            };
        },
        settingPermission() {
            return this.hasPermission('manage_advanced_reservations_rentals_settings') || false;
        },
        permission() {
            return this.hasPermission('edit_cut_off_times') || false;
        }
    },
    methods: {
        async fetchPropertySpaceGroup() {
            try {
                let response = await api.get(
                    this,
                    api.PROPERTIES + this.property_id + "/space-groups"
                );
                this.spaceGroups = response.spaceGroups;
            } catch (err) {
                this.showMessageNotification({ type: 'error', description: err });
            }
        },
        async fetchSpaceGroup() {
            try {
                this.previousSpaceGroupProfile = await api.get(
                    this,
                    api.APW_SETTING + 'properties/' + this.property_id,
                );
                this.enabledProperty = this.previousSpaceGroupProfile?.is_active == 1 ? true : false
                if (this.previousSpaceGroupProfile?.id) {
                    this.form.spaceGroupProfile = this.spaceGroups.find(group => group.id == this.previousSpaceGroupProfile.unit_group_profile_id)
                }
            } catch (error) {
                this.showMessageNotification({ type: 'error', description: err });
            }
        },
        async fetchCutOffTime() {
            let response
            try {
                let property_id = this.isPropertyLevel ? this.property_id : '';
                response = await api.get(this, api.APW_SETTING + 'cut-off-time/' + property_id)
                if (response[0].in_store_time) {
                    this.form.instoreCutoffTime = this.inStoreTime = this.convertToAMPM(response[0].in_store_time)
                }
                else {
                    this.form.instoreCutoffTime = this.inStoreTime = ''
                }
                if (response[0].online_store_time) {
                    this.form.onlineCutoffTime = this.onlineStoreTime = this.convertToAMPM(response[0].online_store_time)          
                }else{
                    this.form.onlineCutoffTime = this.onlineStoreTime = ''                    
                }
            } catch (error) {
                console.log("fetch cut off time error", error);
            }
        },
        async saveSetting() {
            const cutOffTImeData = {
                in_store_time: this.convertToUTC(this.inStoreTime),
                online_store_time: this.convertToUTC(this.onlineStoreTime),
                property_id: this.property_id,
                unit_group_profile_id: this.form.spaceGroupProfile.id,
                property_id: this.isPropertyLevel ? this.property_id : null
            }
            let data;
            let msg = this.message.slice() // create shallow copy
            if (this.previousSpaceGroupProfile?.id) {
                data = {
                    id: this.previousSpaceGroupProfile?.id,
                    unit_group_profile_id: this.form.spaceGroupProfile.id
                };
            } else {
                data = {
                    unit_group_profile_id: this.form.spaceGroupProfile.id
                };
            }
            try {
                if (!this.isPropertyLevel) {
                    // save/update cutOff time
                    await this.saveCutOffTime(cutOffTImeData)
                    msg.pop()

                }
                else {
                    // save/update both
                    if(this.permission){
                        await this.saveCutOffTime(cutOffTImeData)
                    }
                    else{
                        msg.shift()
                    }
                    await this.saveGrouping(data)
                }
                if(this.previousSpaceGroupProfile?.unit_group_profile_id == this.form?.spaceGroupProfile?.id){
                    msg.pop();
                }
                if(this.inStoreTime == this.form?.instoreCutoffTime && this.onlineStoreTime == this.form?.onlineCutoffTime)
                {
                    msg.shift()
                }
                this.fetchSpaceGroup();
                this.fetchCutOffTime();
                this.showMessageNotification({ type: 'success', description: "Success", list: msg });
                this.confirmDialog = false;

            } catch (err) {
                this.showMessageNotification({ type: 'error', description: err });
            }
        },
        async saveGrouping(data) {
            await api.post(
                this,
                api.APW_SETTING + 'properties/' + this.property_id,
                data
            );
        },
        async saveCutOffTime(data) {
            await api.post(this, api.APW_SETTING + 'cut-off-time/', data)
            // return 'cut off time saved successfully'
        },
        convertToUTC(time) {
            const [timePart, modifier] = time.split(' ');
            let [hours, minutes] = timePart.split(':');
            hours = parseInt(hours, 10);
            minutes = parseInt(minutes, 10);

            if (modifier === 'PM' && hours !== 12) {
                hours += 12;
            } else if (modifier === 'AM' && hours === 12) {
                hours = 0;
            }

            // Create a Date object for today with the specified time
            const localDate = new Date();
            localDate.setUTCHours(hours, minutes, 0, 0); // Use setUTCHours to avoid local time zone conversion

            // Get the UTC timestamp directly
            const utcTimestamp = localDate.getTime() + (localDate.getTimezoneOffset() * 60000);

            return utcTimestamp;
        },
        convertToAMPM(utcTimestamp) {
            const date = new Date(parseInt(utcTimestamp, 10));

            const systemTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            const options = {
                                timeZone: systemTimeZone,
                                hour: "numeric", // No leading zero for hours
                                minute: "2-digit", // Two-digit minutes
                                hour12: true, // AM/PM format
                            };

            return new Intl.DateTimeFormat("en-US", options).format(date);

            return `${hours}:${minutes} ${ampm}`;
        },
        async fetchSettings() {
            let property_query=''
            if(this.isPropertyLevel){
                property_query = `&property_id=${this.property_id}`
                let response = await api.get(this.$app, api.SETTINGS + `apw-details?category=leadManagement${property_query}`)
                this.apwSettingState = (response?.settings[0]?.value == 1)  || false
                return;
            }else{
                let apw = await api.get(this, api.SETTINGS + '/check-apw-settings');
                this.apwSettingState = !!apw.apwSettings;
                return;
            }
        },
        resetOriginal(){
            this.form.spaceGroupProfile = this.spaceGroups.find(group => group.id == this.previousSpaceGroupProfile.unit_group_profile_id);
            this.inStoreTime = this.form.instoreCutoffTime;
            this.onlineStoreTime = this.form.onlineCutoffTime;
        },
        async settingConfirmation(){
            if(this.previousSpaceGroupProfile?.unit_group_profile_id == this.form?.spaceGroupProfile?.id || !this.isPropertyLevel){
                await this.saveSetting();
            }else{
                this.confirmDialog = true;
            }
        },
        async updatePropertyFeature(){
            try {
                let data = {
                    id : this.previousSpaceGroupProfile?.id,
                    is_active : this.enabledProperty ? 1 : 0,
                }
                
                await api.put(this, api.APW_SETTING + 'properties/' + this.property_id, data);
                this.showMessageNotification({ type: 'success', description: "successfully updated" });
            } catch (error) {
                console.log("fetch cut off time error", error);
            }
            
        },
        checkForPermissions(){
            if(!this.settingPermission){
                this.showMessageNotification({ title:'Permission Issue', description: "You don't have permission to change space grouping profile"});
            }
        }
    },
    watch : {
        async isPropertyLevel(){
            await this.fetchSettings()
            await this.fetchCutOffTime()
        }
    }
}
</script>
<style scoped>
.cut-off-row-container {
    display: flex;
    align-items: center;
}
</style>